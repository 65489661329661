import { useMutation } from '@apollo/client';
import Layout from '@layouts';
import { getErrorMessages, getInvalidFields } from '@lib';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

import { ContactUsDocument } from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import SelectAndTextInput from '@components/form/SelectAndTextInput';
import { SelectInputValue } from '@components/form/SelectInput';
import TextInput from '@components/form/TextInput';

import { countryValues, mapCountryToSelectValue } from '@data/countries';

import { useUserCountry } from '@lib/hooks/useUserCountry';

type ContactPageProps = {};

const ContactPage: FC<ContactPageProps> = () => {
  const userCountry = useUserCountry();
  const [contactUs] = useMutation(ContactUsDocument);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      country: undefined as (typeof countryValues)[0],
      phoneNumber: '',
      companyName: '',
      jobTitle: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phoneNumber: Yup.string()
        .matches(
          /^\+?(?:[0-9] ?){6,14}[0-9]$/,
          'Please provide a valid phone number.'
        )
        .required('Required'),
      companyName: Yup.string().required('Required'),
      jobTitle: Yup.string().required('Required'),
      message: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const toastId = toast.loading('Sending message...');
      try {
        await contactUs({
          variables: {
            message: {
              name: values.name,
              email: values.email,
              country: values.country.value.isoCode,
              phoneNumber: values.phoneNumber,
              companyName: values.companyName,
              jobTitle: values.jobTitle,
              message: values.message,
            },
          },
        });
        // success
        formik.resetForm({
          values: {
            ...formik.initialValues,
            country: mapCountryToSelectValue(true, userCountry),
          },
        });

        toast.success('Message sent!', { id: toastId });
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        toast.error(errorMessages?.join('\n') ?? 'Failed to send message', {
          id: toastId,
          duration: 10 * 1000,
        });
        if (!errorMessages) console.error('[ContactPageForm] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!formik.values.country && userCountry) {
      formik.setFieldValue(
        'country',
        mapCountryToSelectValue(true, userCountry)
      );
    }
  }, [userCountry]);

  useEffect(() => {
    if (formik.values.country && formik.values.phoneNumber === '') {
      formik.setFieldValue('phoneNumber', formik.values.country.value.dialCode);
    }
  }, [formik.values.country]);

  return (
    <Layout url="/contact/" title="Contact Us">
      <h1 className="text-3xl lg:text-4xl font-emp text-center lg:text-left mt-20 lg:mt-30 mb-8">
        Contact Us
      </h1>

      <p className="text-center lg:text-left opacity-70">
        Do you have any questions about our Platform or Services? Do not
        hesitate and send us your questions! All you have to do is fill in the
        form below and we will contact you as soon as possible.
      </p>

      <form onSubmit={formik.handleSubmit} className="mb-0">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-16 gap-y-4">
          <TextInput
            name="name"
            formik={formik}
            label="Name"
            className="col-span-1"
          />

          <TextInput
            name="email"
            formik={formik}
            label="Email"
            className="col-span-1 lg:col-start-2"
          />

          <SelectAndTextInput
            className="col-span-1 lg:col-start-3"
            formik={formik}
            label="Phone Number"
            select={{
              name: 'country',
              values: countryValues,
              reactSelectProps: {
                menuPlacement: 'top',
                getOptionLabel: (countryValue: SelectInputValue) =>
                  countryValue.value?.name ?? '',
              },
            }}
            text={{
              name: 'phoneNumber',
            }}
          />

          <TextInput
            name="companyName"
            formik={formik}
            label="Company Name"
            className="col-span-1"
          />

          <TextInput
            name="jobTitle"
            formik={formik}
            label="Job Title"
            className="col-span-1 lg:col-start-2"
          />

          <TextInput
            name="message"
            formik={formik}
            label="Message"
            type="textarea"
            placeholder="How can we help?"
            className="col-span-1 lg:col-span-3"
          />
        </div>

        <div
          className={clsx(
            'flex justify-center mt-4 lg:mt-10',
            'px-4 py-4 lg:p-0 -mx-4 lg:mx-0 '
          )}
        >
          <Button type="submit" variant="primary" className="w-full">
            Send Message
          </Button>
        </div>
      </form>

      <h2 className="text-2xl font-emp mt-10 mb-3">Contact</h2>
      <div className="opacity-70">
        <a href="mailto:info@oilynx.com">info@oilynx.com</a>
      </div>
      <h2 className="text-2xl font-emp mt-10 mb-3">Brought to you by</h2>
      <div className="opacity-70 mb-10">
        Redsleeve SA
        <br />
        Route de Florissant 47BIS
        <br />
        Genève, GENÈVE, 1206
        <br />
        Switzerland
      </div>
    </Layout>
  );
};

export default ContactPage;
